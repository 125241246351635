.error-dialog-container div:nth-child(3)>div{
  position: absolute;
  bottom:0px;
  margin:0px;
}

.categories-container::-webkit-scrollbar{
  height: 7px;
  width: 10px;
  border: 1px solid #fff;
}

.categories-container::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.categories-container::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

.categories-container::-webkit-scrollbar-thumb:hover {
  background: black; 
}

.progress-bar-value {
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.container2::-webkit-scrollbar{
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
}

.container2::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.container2::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

.container2::-webkit-scrollbar-thumb:hover {
  background: black; 
}


.scrollbar-container3::-webkit-scrollbar{
    height: 5px;
    width: 5px;
    border: 1px solid #fff;
}
  
.scrollbar-container3::-webkit-scrollbar-track {
    border-radius: 0;
    background: transparent;
}

.scrollbar-container3::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #b0b0b0;
}

.scrollbar-container3::-webkit-scrollbar-thumb:hover {
    background: black; 
}

.container1::-webkit-scrollbar{
  height: 8px;
  width: 7px;
  border: 1px solid #fff;
}

.container1::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.container1::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

input[type=range] {
  background: linear-gradient(#808080, #808080) no-repeat center;
  background-size: 100% 2px;
}

input[type=range]::-webkit-slider-thumb {
  height: 28px;
  width: 28px;
  border-radius: 28px;
  background-color: #fff;
  position: relative;
  margin: 5px 0;
  cursor: pointer;
  appearance: none;
  pointer-events: all;
  box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
}

input[type=range]::-webkit-slider-thumb::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 13px;
  left: 100%;
  width: 2000px;
  height: 2px;
}

input[type=range]:nth-child(2) {
  background: none;
}
input[type=range]:nth-child(2)::-webkit-slider-thumb::before {
  background-color: grey;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  color: black;
  background-color: #fff;
}

.checkbox {
  background-color: white !important;
  border-radius:0px !important;
  border:1px solid black !important
}

input {
    background: inherit;
}